<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-preview-exercise-admin question-preview">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">{{ question.title }}</p>
			<div class="basic-info-container">
				<div>
					<p>
						{{ question.level_name }}
					</p>
					<p>{{ question.chapter_name }}</p>
				</div>
				<div>
					<p>
						{{ question.difficulty_name }}
					</p>
				</div>
			</div>
			<div class="img-container">
				<p class="subtitle">Texte</p>
				<img v-if="displayedImg" :src="displayedImg" alt="">
				<p
					class="calcul" v-html="question.question"
				> </p>
				<p v-for="answer in question.answers" :key="answer.id" v-html="answer.answer"></p>

			</div>
			<select-status v-if="checkRouteAdmin()" :id="question.id" @close="$emit('close')" @updateQuestion="$emit('updateQuestion', question.id)" />
		</div>
	</pop-up-wrapper>
</template>

<script>
import SelectStatus from './SelectStatus.vue';
import PopUpWrapper from '../PopUpWrapper.vue'
import { authenticatedImageFetch } from '../../../utils'
export default {
	components: { PopUpWrapper, SelectStatus },
	emits: ['close'],
	props: {
		question: Object,
	},
	data() {
		return {
			displayedImg: ''
		}
	},
	

	methods: {
		checkRouteAdmin() {
			return this.$route.path.includes('/super-admin/')
		},

		
		async getImg(){
			const res = await authenticatedImageFetch(this.question.image)
				.then(res => res.blob())

			this.displayedImg = URL.createObjectURL(res)
		}
	},

	created() {
		if(this.question.image){
			this.getImg()
		}
	},
	

}
</script>

<style>
</style>
