<template>
	<all-qcms :status="'PENDING'" />
</template>
<script>
import AllQcms from './AllQcms.vue'
export default{
	components: {
		AllQcms
	}

}
</script>
