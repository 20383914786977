<template>
	<section>
		<router-link :to="{name: status == 'VALIDATED' ? 'SuperAdminPendingQcms': 'SuperAdminAllQcms'}"  class="submit dark">Voir les questions <span v-if="status == 'VALIDATED'"> en attente</span> <span v-else>validés</span></router-link>
		<h1>Liste QCM <span v-if="status == 'VALIDATED'"> validés</span> <span v-else>en attente</span></h1>
		<pagination-component
			v-if="pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="getNewQuestions"
		/>
		<FilterWrapper @updateFilter="updateFilter" :show-search="false" :show-filter="false" />
		
		<table style="width: 100%;">
			<tr>
				<th></th>
				<th>Titre</th>
				<th>Auteur</th>
				<th>Séquence</th>
				<th>Niveau</th>
				<th>Voir</th>
				<th>Modifier</th>
				<th>Supprimer</th>
				<th>Status</th>
			</tr>
			<tr v-for="(question, index) in questions" :key="index">
				<td>{{ index + 1 }}</td>
				<td v-html="question.question"></td>
				<td>{{ question.teacher_name }}</td>
				<td
					v-if="
						this.$store.state.sequences.length !== 0 &&
						this.$store.state.chapters.length !== 0
					"
				>
					{{ question.sequence_name }}
				</td>
				<td v-if="this.$store.state.levels.length !== 0">
					{{ question.level_name }}
				</td>
				<td>
					<button class="see" @click="showPopUpPreview(question)">
						<img src="/img/super-admin/blue-eye.svg" alt="" />
					</button>
				</td>
				<td>
					<router-link
						class="table-btn"
						v-if="checkIfSuperAdminAndValidatedStatus(question.status)"
						:to="{
							name: 'SuperAdminEditQcm',
							params: { id: question.id },
						}"
						>Modifier</router-link
					>
				</td>
				<td>
					<button
						class="delete"
						v-if="checkIfSuperAdminAndValidatedStatus(question.status)"
						@click="deleteQuestion(question.id)"
					>
						<img src="/img/super-admin/blue-trash.svg" alt="" />
					</button>
				</td>
				<td> <span :style="getStyleColor(question.status)">{{  translate(question.status)  }}</span></td>
				
			</tr>
			
		</table>
		<Teleport to="body">
			<pop-up-preview-qcm-question
				v-if="isPopUpPreviewVisible"
				@close="isPopUpPreviewVisible = false"
				@updateQuestion="removeQuestion"
				:question="selectedQuestion"
			/>
		</Teleport>
	</section>
</template>
<script>
import { filteredQCMQuestionsService,deleteQCMQuestionService } from '../../../services/qcmQuestionsServices'
import PaginationComponent from '../../Utils/PaginationComponent.vue'
import PopUpPreviewQcmQuestion from '../../PopUp/QCM/PopUpPreviewQcmQuestion.vue'
import FilterWrapper from '../../Admin/Search/FilterWrapper.vue'

export default {
	components: {
		PaginationComponent,
		PopUpPreviewQcmQuestion,
		FilterWrapper,
	},
	props: {
		status: {
			type: String,
			default: 'VALIDATED',
		}
	},
	data() {
		return {
			questions: [],
			currentPage: 0,
			pages: 0,
			filters: {
				level: '',
				teacher: '',
				chapter: '',
				status: '',
			},
			isPopUpPreviewVisible: false,
			selectedQuestion: null,
		}
	},
	methods: {
		removeQuestion(id) {
			this.questions = this.questions.filter((question) => question.id !== id)
		},
		showPopUpPreview(question){
			this.isPopUpPreviewVisible = true
			this.selectedQuestion = question
		},
		translate(status) {
			switch (status) {
				case 'VALIDATED':
					return 'Approuvé'
				case 'REFUSED':
					return 'Refusé'
				case 'PENDING':
					return 'En attente'
			}
		},
		getStyleColor(status) {
			switch (status) {
				case 'VALIDATED':
					return `color: #17A81C`
				case 'PENDING':
					return `color: #fff;background-color:#F0961A;`
				case 'REFUSED':
					return `color: #DE1D1D`
				case 'PRIVATE':
					return `color: #000`
			}
		},
		async allQcmQuestions() {
			const filter = `?status=${this.status}`
			const res  = await filteredQCMQuestionsService(filter)
			this.questions = res.results
			const total = res.count
			this.pages = Math.ceil(total / 20)

		},
		checkIfSuperAdminAndValidatedStatus(status) {

			if (status == 'VALIDATED') {
				if (this.$store.state.user.role == 'SUPER_ADMIN')
					return true
				return false
			}
			return true
		},
		async deleteQuestion(id) {
			if (confirm("Êtes-vous sûr de vouloir supprimer ?")) {
				await deleteQCMQuestionService(id)
				this.getNewQuestions()
			}
		},
		updateFilter(data) {
			this.filters.level = data.level
			this.filters.subject = data.subject
			this.filters.chapter = data.chapter
			this.getNewQuestions()
		},
		async getNewQuestions(e){
			this.currentPage = e || 0
			const chapter = this.filters.chapter ? `chapter=${this.filters.chapter}` : 'chapter='
			const level = this.filters.level ? `&level=${this.filters.level}` : '&level='
			const teacher = this.filters.teacher ? `&teacher=${this.filters.teacher}` : '&teacher='
			const subject = this.filters.subject ? `&subject=${this.filters.subject}` : '&subject='
			this.filter = `?${chapter}${level}${teacher}${subject}&offset=${this.currentPage * 20}&status=${this.status}`
			
			const res = await filteredQCMQuestionsService(this.filter)
			this.questions = res.results
			const total = res.count
			this.pages = Math.ceil(total / 20)
		}
	},
	created() {
		this.allQcmQuestions()
	},
}
</script>
